import React, {useState, useEffect} from 'react';
import {format, differenceInHours, differenceInMinutes} from 'date-fns';
import { DONOR_STATUS_SHOW_UP } from '../../Configuration/Modals/constants';
import CustomSelect from '../../common/FormFields/Select';
import Modal from '../../common/Modal';
import CustomScrollbar from '../../common/CustomScrollbar';
import Radio from '../../common/FormFields/Radio';
import Button from '../../common/FormFields/Button';
import Loader from '../../common/Loader';
import {getNextDonorStatus, isDateGreaterThanToday} from "../../../services/helpers";
import { useTranslation } from 'react-i18next';

const DonorSettings = (props) => {
  const { 
    markDidAttend,
    markDidNotAttend,
    deleteDonorSettings,
    toggleDonorSettingsModal,
    currentBloodbank,
    donorSettingsData,
    isUserPending,
    isModalOpen,
    userRoleName,
  } = props;


  const [data, setData] = useState({});
  const [attendance, changeAttendance] = useState(true);
  const [status, changeStatus] = useState('');
  const [isDisabled, changeDisabled] = useState(true);
  const [nextDonorStatus, setNextDonorStatus] = useState(DONOR_STATUS_SHOW_UP[0]);
  const [currentDonorStatus, setCurrentDonorStatus] = useState(DONOR_STATUS_SHOW_UP[0]);
  const { t } = useTranslation();
  const donorStatusShowUp = DONOR_STATUS_SHOW_UP.map(donorStatus => ({ ...donorStatus, label: t(`common.donorStatus.${donorStatus.label}`) }))

  const handleCheck = (value) => {
    changeDisabled(false);
    changeAttendance(value === 'yes');
  };

  useEffect(() => {
    if (donorSettingsData) {
      setData(donorSettingsData);
    } else {
      setData({});
      changeAttendance(true);
      changeStatus('');
      changeDisabled(true);
      setNextDonorStatus(DONOR_STATUS_SHOW_UP[0]);
      setCurrentDonorStatus(DONOR_STATUS_SHOW_UP[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donorSettingsData]);

  useEffect(() => {
    setNextDonorStatus(attendance ? getNextDonorStatus(currentDonorStatus) : currentDonorStatus);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendance, isDisabled]);

  useEffect(() => {
    if (data.user || data.reservation) {
      const { status } = data.user || data.reservation.user;
      const currentStatus = DONOR_STATUS_SHOW_UP.find(item => item.value === (status || 'new'));
      setCurrentDonorStatus(currentStatus);
      setNextDonorStatus(currentStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data]);

  const handleStatus = (data) => {
    changeStatus(data.value);
  }

  const getFormattedDate = (date) => {
    const dayOfWeek = t(`common.day.${format(new Date(date), 'EEEE')}`);
    const dayOfMonth = format(new Date(date), 'd');
    const month = t(`common.month.${format(new Date(date), 'LLLL')}`);
    const year = format(new Date(date), 'yyyy');

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
  };

  const handleClose = () => {
    toggleDonorSettingsModal();
    deleteDonorSettings();
  };

  const handleDidAttend = (data, donorStatus) => {
    markDidAttend('did_attend', data, donorStatus, currentBloodbank);
  };

  const handleDidNotAttend = (data, donorStatus) => {
    markDidNotAttend('did_not_attend', data, donorStatus, currentBloodbank);
  };

  const formatText = (text) => {
    const firstPart = text.slice(0, 6);
    const secondPart = text.slice(6, 11);
    return `${firstPart}\u00A0${secondPart}`;
  };

  const handleCopy = (e) => {
    e.clipboardData.setData('text/plain', data.user ? (data.user.pnr || '-') : 
    data.reservation ? (data.reservation.user.pnr || '-') : '-');
    e.preventDefault();
  };  

  const handleCopyPnr = () => {
    navigator.clipboard.writeText(formatText(data.user.pnr))
  }

  return (
    <Modal isModalOpen={ isModalOpen } handleModalClose={ handleClose } { ...props }>
      <div className="modal-header">
        <h2 className="modal-title">{ t(`common.donorStatus.${currentDonorStatus.label}`) }</h2>
      </div>
      <CustomScrollbar className="modal-body">
    
          <section className="modal-content">
            { isUserPending ? 
              <Loader /> :   
              <>
                <div className="form-group">
                  <h3 className="modal-subtitle">
                    {data.user ? `${data.user.firstname} ${data.user.lastname}` : 
                      data.reservation ? `${data.reservation.user.firstname} ${data.reservation.user.lastname}`: null}
                  </h3>
                </div>
                <div className="form-group">
                  <p className="text">
                    {data.startDate ? getFormattedDate(data.startDate) : null}
                  </p>
                  <p className="text">
                    {data.startDate ? `${format(new Date(data.startDate), 'H')}:${format(new Date(data.startDate), 'mm')} - 
                      ${format(new Date(data.endDate), 'H')}:${format(new Date(data.endDate), 'mm')} (${differenceInHours(data.endDate, data.startDate) > 0 ? differenceInHours(data.endDate, data.startDate) + 'h ' + differenceInMinutes(data.endDate - 3600000, data.startDate): differenceInMinutes(data.endDate, data.startDate)}m)` : null}
                  </p>
                </div>
                <div className="form-group d-flex">
                  <p className="text italic" onCopy={ handleCopy } style={{marginRight : 10}} >
                    { data.user ? (data.user.pnr ? `${formatText(data.user.pnr)}` : '-') : 
                      data.reservation ? (data.reservation.user.pnr ? `${formatText(data.reservation.user.pnr)}` : '-') : '-' }
                  </p>  
                  <i className="icon-copy" onClick={handleCopyPnr}/>        
                </div>
              </>
            }
          </section>
        <section className="modal-content light">
          <div className="form-group">
            <h3 className="modal-subtitle">{t('showUp.header')}</h3>
          </div>
          <div className="form-group inputs-group">
            <div className="inputs-group-item">
              <Radio label={t('showUp.yes')} name="show_up" returnValue = {handleCheck} checked={!isDisabled && attendance} value={'yes'} />
            </div>
            <div className="inputs-group-item">
              <Radio label={t('showUp.no')} name="show_up" returnValue = {handleCheck} checked={!isDisabled && !attendance} value={'no'}/>
            </div>
          </div>
          <div className="form-group">
            <CustomSelect
              label={t('common.donorStatus.header')}
              value={ donorStatusShowUp.find(item => item.value === status) || { ...nextDonorStatus, label: t(`common.donorStatus.${nextDonorStatus.label}`)} }
              data={ donorStatusShowUp }
              additionalClassName="stretched"
              onChange={handleStatus}
              isDisabled={isDisabled}
            />
          </div>
        </section>
      </CustomScrollbar>
      <div className="modal-footer">
        <div className="inputs-group">
          <div className="inputs-group-item">
            <Button type="button" text={t('common.cancel')} onClick={ handleClose } />
          </div>
          <div className="inputs-group-item">
            <Button 
              btnClassName="btn-primary" 
              type="button" 
              disabled={userRoleName !== 'admin' || isDisabled || isUserPending || isDateGreaterThanToday(data.startDate)} 
              text={t('common.save')} 
              onClick={ attendance
                ? () => {handleDidAttend(data, status || nextDonorStatus.value); handleClose();}
                : () => {handleDidNotAttend(data, status || nextDonorStatus.value); handleClose()}
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DonorSettings;
